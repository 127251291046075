import { authService, TokenData } from '@innovamat/radiance-utils';
import { GraphQLClient, RequestDocument } from 'graphql-request';

interface Variables {
  [key: string]: any;
}

class ExpiredTokenError extends Error {
  response: any;

  constructor(message: string) {
    super(message);
    this.name = 'ExpiredTokenError';
    this.response = {
      errors: [
        {
          extensions: {
            response: {
              body: {
                message: 'Expired JWT',
                type: 'auth.expired_jwt',
                detail: 'Signature has expired.',
              },
            },
          },
        },
      ],
    };
  }
}

export class CustomGraphQLClient {
  private graphqlClient: GraphQLClient;
  private refreshTokenInProgress = false;

  private getAuthToken;
  private getUserAcceptLanguage;
  private onRefreshToken;

  constructor(
    url: string,
    getAuthToken: () => string,
    getUserAcceptLanguage: () => string | undefined,
    onRefreshToken: () => Promise<TokenData>
  ) {
    this.getAuthToken = getAuthToken;
    this.getUserAcceptLanguage = getUserAcceptLanguage;
    this.onRefreshToken = onRefreshToken;

    this.graphqlClient = new GraphQLClient(url, {
      headers: {
        authorization: getAuthToken(),
      },
    });
  }

  isExpired(error: any): boolean {
    return (
      error.extensions?.response?.['body']?.message === 'Expired JWT' ||
      error.extensions?.response?.['body']?.type === 'auth.expired_jwt' ||
      error.extensions?.response?.['body']?.detail === 'Signature has expired.'
    );
  }

  async request<T = any, V = Variables>(
    query: RequestDocument,
    variables?: V,
    headers?: Record<string, string>
  ): Promise<T> {
    try {
      const language = this.getUserAcceptLanguage();

      if (language) {
        this.graphqlClient.setHeader('accept-language', language);
      }

      this.graphqlClient.setHeader('authorization', this.getAuthToken());

      if (headers) {
        Object.keys(headers).forEach((key) => {
          this.graphqlClient.setHeader(key, headers[key]);
        });
      }

      if (
        authService.isTokenExpiringSoon(
          this.getAuthToken().replace('Bearer ', '')
        )
      ) {
        throw new ExpiredTokenError('Token is expiring soon');
      }

      return await this.graphqlClient.request<T>(query, variables as Variables);
    } catch (error: any) {
      if (
        'response' in error &&
        error.response.errors.some(this.isExpired) &&
        !this.refreshTokenInProgress
      ) {
        this.refreshTokenInProgress = true;
        try {
          await this.onRefreshToken();
          const language = this.getUserAcceptLanguage();

          if (language) {
            this.graphqlClient.setHeader('accept-language', language);
          }

          this.graphqlClient.setHeader('authorization', this.getAuthToken());
        } finally {
          this.refreshTokenInProgress = false;
        }
        return this.graphqlClient.request<T>(query, variables as Variables);
      } else {
        // only log the error if it's not an expired token error
        console.log('Error in request', { error });
        throw error;
      }
    }
  }
}
