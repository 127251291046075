import { Session } from '@innovamat/resource-viewer';
import { TFunction } from 'i18next';
import { createContext, useContext, useState } from 'react';
import { PlayerInfo } from '../types/PlayerInfo';
import { SmartGuideType } from '../types/SmartGuide';
import { Stage } from '../types/Stage';

export type SmartGuidesProviderProps = {
  children: React.ReactNode;
  translations: Record<string, string>;
  playerInfo?: PlayerInfo;
  smartGuide: SmartGuideType;
  session: Session;
  t: TFunction<'translation', undefined, 'translation'>;
  relatedItemsComponent?: JSX.Element;
  unblockedAppletsComponent?: JSX.Element;
  setEventData: (event: string, data: Record<string, any>) => void;
  classroomId?: string;
  region?: string;
};

type SmartGuideValue = {
  playerInfo?: PlayerInfo;
  t: TFunction<'translation', undefined, 'translation'>;
  smartGuide: SmartGuideType;
  stage: Stage;
  translations: Record<string, string>;
  session: Session;
  relatedItemsComponent?: JSX.Element;
  unblockedAppletsComponent?: JSX.Element;
  isAssessableDrawerOpen: boolean;
  setIsAssessableDrawerOpen: (value: boolean) => void;
  setEventData: (event: string, data: Record<string, any>) => void;
  classroomId?: string;
  region?: string;
};

const SmartGuidesContext = createContext<SmartGuideValue | undefined>(
  undefined
);

function SmartGuidesProvider({
  children,
  playerInfo,
  translations,
  smartGuide,
  session,
  t,
  relatedItemsComponent,
  unblockedAppletsComponent,
  setEventData,
  classroomId,
  region,
}: SmartGuidesProviderProps) {
  const typeToStage = {
    SmartGuidePrimaria: Stage.Primary,
    SmartGuideSecundaria: Stage.Secondary,
    SmartGuideOpen: Stage.Secondary,
    SmartGuideTaller: Stage.EarlyYear,
  };
  const stage = typeToStage[smartGuide.__typename];

  const [isAssessableDrawerOpen, setIsAssessableDrawerOpen] = useState(false);

  const value: SmartGuideValue = {
    playerInfo,
    t,
    smartGuide,
    stage,
    translations,
    session,
    relatedItemsComponent,
    unblockedAppletsComponent,
    isAssessableDrawerOpen,
    setIsAssessableDrawerOpen,
    setEventData,
    classroomId,
    region,
  };

  return (
    <SmartGuidesContext.Provider value={value}>
      {children}
    </SmartGuidesContext.Provider>
  );
}

function useSmartGuides() {
  const context = useContext(SmartGuidesContext);
  if (context === undefined) {
    throw new Error('useSmartGuides must be used within a SmartGuidesProvider');
  }
  return context;
}

export { SmartGuidesProvider, useSmartGuides };
