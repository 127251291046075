import { useEffect, useState } from 'react';

import * as yup from 'yup';

import { getAvatars } from '@innovamat/glimmer-assets';

import {
  ImageSelector,
  Input,
  Modal,
  Select,
  Typography,
} from '@innovamat/glimmer-components';
import {
  Classroom,
  ClassroomBody,
  GlowApiError,
  Level,
  useCourses,
  useOrganizationQuery,
} from '@innovamat/glow-api-client';

import styled from '@emotion/styled';
import { useFlag } from '@innovamat/flags';
import { getRandomItem, newId } from '@innovamat/radiance-utils';
import { useTranslation } from 'react-i18next';
import { normalizeImgSource } from '../../../../utils';
import { SCHEDULE_DEFAULT_VALUES } from '../../constants';
import { useClassroomScheduleValues } from '../../hooks/use-classroom-schedule-values';
import { usePutClassroom } from '../../hooks/use-put-classroom';
import { ClassroomSchedule } from '../classroom-schedule';

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
  width: 100%;
  > div {
    flex: 1;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const schema = yup.object().shape({
  name: yup.string().required(),
  courseId: yup.string().required(),
});

type Props = {
  classroom?: Classroom | undefined;
  onClose: () => void;
  show: boolean;
  availableCoursesIds?: string[];
  onSuccess?: (classroomBody: ClassroomBody) => void;
  onError?: (errors: GlowApiError) => void;
  organizationId: string;
  region: string;
  isCourseSelectorAutofilled?: boolean;
};

type TempClassroom = {
  avatar: string | null;
  courseId: string;
  icon: number | null;
  name: string;
  level: Level;
};

export function AddClassroomModal({
  classroom,
  onClose,
  show,
  availableCoursesIds,
  onSuccess,
  onError,
  organizationId,
  region,
  isCourseSelectorAutofilled,
}: Props): JSX.Element | null {
  const { t } = useTranslation();
  const { handlePutClassroom, isPending } = usePutClassroom({
    onError,
    onSuccess,
  });

  const { data: organizationQuery } = useOrganizationQuery(
    { id: organizationId },
    { staleTime: Infinity, enabled: !!organizationId }
  );

  const timezone = organizationQuery?.organization?.timezone || '';

  const [state, setState] = useState<TempClassroom>({
    name: '',
    courseId: '',
    icon: null,
    avatar: null,
    level: Level.DEFAULT,
  });

  const { value: isFeatureAvailable } = useFlag(
    'classroom-schedule-config',
    false,
    {
      identifier: '',
      custom: {
        schoolId: organizationQuery?.organization?.id || '',
      },
    }
  );

  const schedule = useClassroomScheduleValues();

  useEffect(() => {
    setState({
      avatar: classroom?.avatar || null,
      courseId: classroom?.courseId || '',
      icon: classroom?.icon || null,
      name: classroom?.name || '',
      level: (classroom?.level as Level) || Level.DEFAULT,
    });

    schedule.handleInitValues({
      scheduleStart:
        classroom?.scheduleStart || SCHEDULE_DEFAULT_VALUES.scheduleStart,
      scheduleEnd:
        classroom?.scheduleEnd || SCHEDULE_DEFAULT_VALUES.scheduleEnd,
      timeZone: timezone, // agarrar de la organizacion
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, classroom, timezone]);

  const [isValid, setIsValid] = useState(false);

  const { validCoursesByCourse, toOptions, courses } = useCourses({
    organizationId: organizationId,
    regionCode: region,
  });

  const coursesOptions = toOptions(
    validCoursesByCourse(classroom?.courseOrder || null)
  ).filter((option) => {
    if (!availableCoursesIds) return true;
    return availableCoursesIds.includes(option.value);
  });

  const isEditing = !!classroom?.id;

  const selectedCourse = courses.find((course) => course.id === state.courseId);

  const avatarImages = selectedCourse
    ? Object.entries(getAvatars(selectedCourse?.order!)).map(
        ([key, value]) => ({
          id: +key,
          image: normalizeImgSource(value),
        })
      )
    : [];

  const handleSend = (): void => {
    const body: ClassroomBody = {
      id: classroom?.id || newId(),
      icon: state.icon || getRandomItem(avatarImages).id,
      organizationId,
      level: state.level,
      name: state.name,
      courseId: state.courseId,
      avatar: state.avatar,
      scheduleEnd: schedule.values.scheduleEnd,
      scheduleStart: schedule.values.scheduleStart,
    };
    handlePutClassroom(body, schedule.values.timeZone!);
  };

  const handleInputChange = (
    value: string | number | null,
    inputName: keyof TempClassroom
  ): void => {
    setState((prevState) => ({ ...prevState, [inputName]: value }));
  };

  useEffect(() => {
    async function validateFields(): Promise<void> {
      const valid = await schema.isValid(state);
      setIsValid(valid);
    }
    validateFields();
  }, [state]);

  const isAllDataValid = schedule.areAllFieldsFilled && isValid;

  if (!show) return null;

  return (
    <Modal
      modalWidth={700}
      buttons={[
        {
          children: isEditing ? t('common.save') : t('common.create'),
          onClick: isPending ? undefined : handleSend,
          variant: 'accent',
          disabled: !isAllDataValid,
          loading: isPending,
        },
        {
          children: t('common.close'),
          onClick: onClose,
          variant: 'tertiary',
        },
      ]}
      isOpen={show}
      onClose={onClose}
      title={
        isEditing
          ? t('classroom.title.editClassroom')
          : t('classroom.title.createClassroom')
      }
    >
      <ModalContent>
        <Section>
          <Typography.Subtitle2>
            {t('classroom.inputs.name-and-course', 'Nombre y curso')}
          </Typography.Subtitle2>
          <Content>
            <Input
              ref={(input) => input?.focus()}
              labelText={t('classroom.input.label.classname')}
              type="text"
              onChange={(e) => handleInputChange(e.target.value, 'name')}
              placeholder={t('classroom.input.placeholder.writeName')}
              value={state.name}
            />
            <Select
              menuPortalTarget={document.body}
              labelText={t('classroom.input.placeholder.course')}
              onChange={(option) =>
                option?.value && handleInputChange(option?.value, 'courseId')
              }
              status={isCourseSelectorAutofilled ? 'autofilled' : undefined}
              options={coursesOptions}
              placeholder={t('classroom.input.label.chooseCourse')}
              value={state.courseId}
            />
          </Content>
        </Section>

        {isFeatureAvailable && <ClassroomSchedule {...schedule} />}

        <Section>
          <Typography.Subtitle2>Imagen de avatar</Typography.Subtitle2>
          <ImageSelector
            images={avatarImages}
            noImageText={t('classroom.selectImage.defaultText')}
            onSelectAvatar={(avatar) => handleInputChange(avatar, 'avatar')}
            onSelectIcon={(id) => handleInputChange(id, 'icon')}
            selectedAvatar={state.avatar}
            selectedCourseOrder={selectedCourse?.order}
            selectedIcon={state.icon}
            t={t}
          />
        </Section>
      </ModalContent>
    </Modal>
  );
}
