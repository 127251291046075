import { isEmpty } from '@innovamat/radiance-utils';
import { type ChangeEvent, ReactNode, createContext, useContext } from 'react';
import type { SettingsTabType, User } from '..';
import useSettings from '../hooks/use-settings';

type MySettingsValue = {
  allPositions?: string[];
  confirmPassword: string;
  contentDrawerIsOpen: boolean;
  country: string | undefined;
  filteredLangs: { value: string; label: string; key: string }[];
  isLoading: boolean;
  isPageLoading: boolean;
  isPositionsDisabled: boolean;
  isSavingSettings: boolean;
  isSubmitEnabled: boolean;
  isSuccessSettings: boolean;
  language: string;
  password: string;
  phoneCode: string | undefined;
  schoolName: string | undefined;
  settingsValues: {
    firstName: string;
    lastName: string;
    phoneNumber: string | null;
  };
  showDrawer: boolean;
  showLanguageSelector: boolean;
  showModalSave: boolean;
  userEmail: string | undefined;
  changeActiveTab: (newTab: SettingsTabType) => void;
  getChecked: (key: string) => boolean;
  getConfirmPasswordErrorMessage: () => string | undefined;
  getCurrentTitle: () => string;
  getTitle: (tab: SettingsTabType) => string;
  handleChangeCheckbox: (role: string) => void;
  handleChangeConfirmPassword: (
    e: ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  handleChangeLang: (value: string) => void;
  handleChangePassword: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleInputPhone: (e: any, country: any) => void;
  isConfirmPasswordValid: (_confirmPassword: string) => boolean;
  isPasswordValid: (_password: string) => boolean;
  isPhoneValid: (phone: any, phoneDial: any) => boolean;
  isTabActive: (tab: SettingsTabType) => boolean;
  isValueEmpty: (value?: any) => boolean;
  onCancel: () => void;
  onCloseContentDrawer: () => void;
  onCloseModal: () => void;
  onGoBack: () => void;
  onSaveChanges: () => void;
  updateField: (
    name: 'firstName' | 'lastName' | 'phoneNumber',
    value: string
  ) => void;
  useGoogleClassroomSync: any;
};

const MySettingsContext = createContext<MySettingsValue | undefined>(undefined);

type MySettingsProviderProps = {
  children: ReactNode;
  user: User;
  goBack: () => void;
  onSuccess: () => void;
  useGoogleClassroomSync: any;
};

export function MySettingsProvider({
  children,
  user,
  goBack,
  onSuccess,
  useGoogleClassroomSync,
}: MySettingsProviderProps): JSX.Element {
  const {
    allPositions,
    confirmPassword,
    contentDrawerIsOpen,
    country,
    filteredLangs,
    isLoading,
    isPageLoading,
    isPositionsDisabled,
    isSavingSettings,
    isSubmitEnabled,
    isSuccessSettings,
    language,
    password,
    phoneCode,
    schoolName,
    settingsValues,
    showDrawer,
    showLanguageSelector,
    showModalSave,
    userEmail,
    changeActiveTab,
    getChecked,
    getConfirmPasswordErrorMessage,
    getCurrentTitle,
    getTitle,
    handleChangeCheckbox,
    handleChangeConfirmPassword,
    handleChangeLang,
    handleChangePassword,
    handleInputPhone,
    isConfirmPasswordValid,
    isPasswordValid,
    isPhoneValid,
    isTabActive,
    onCancel,
    onCloseContentDrawer,
    onCloseModal,
    onGoBack,
    onSaveChanges,
    updateField,
  } = useSettings({ user, goBack, onSuccess });

  const value: MySettingsValue = {
    schoolName,
    userEmail,
    showLanguageSelector,
    settingsValues,
    phoneCode,
    filteredLangs,
    country,
    language,
    password,
    confirmPassword,
    allPositions,
    isPageLoading,
    isSubmitEnabled,
    isLoading,
    showModalSave,
    contentDrawerIsOpen,
    showDrawer,
    isPositionsDisabled,
    isSavingSettings,
    isSuccessSettings,
    isValueEmpty: isEmpty,
    isPhoneValid,
    isPasswordValid,
    isConfirmPasswordValid,
    getConfirmPasswordErrorMessage,
    getChecked,
    updateField,
    handleInputPhone,
    handleChangeLang,
    handleChangePassword,
    handleChangeConfirmPassword,
    handleChangeCheckbox,
    onCancel,
    onSaveChanges,
    changeActiveTab,
    isTabActive,
    getTitle,
    getCurrentTitle,
    onCloseModal,
    onGoBack,
    onCloseContentDrawer,
    useGoogleClassroomSync,
  };

  return (
    <MySettingsContext.Provider value={value}>
      {children}
    </MySettingsContext.Provider>
  );
}

export function useMySettings(): MySettingsValue {
  const context = useContext(MySettingsContext);
  if (context === undefined) {
    throw new Error('useMySettings must be used within a MySettingsProvider');
  }
  return context;
}
